


























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "ContextMenu",
  props: {
    value: { type: Object, required: true },
  },
});
